export function TO_YEAR(data: number, isYear = false) {
	let month = 0;
	let year = 0;
	if (data > 24) {
		year = Math.floor(data / 12);
		month = data - year * 12;
		if (month === 0) {
			return year + `${isYear ? ' năm' : ' tuổi'}`;
		}
		return year + `${isYear ? ' năm ' : ' tuổi '}` + month + ' tháng';
	}
	return data + ' tháng';
}
