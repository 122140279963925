import { authService } from '@/services/auth.service';
import { orgService } from '@/services/org.service';
import { staffService } from '@/services/staff.service';
import { roomService } from '@/services/room.service';
import { dayCareClassService } from '@/services/dayCareClass.service';
import { studentService } from '@/services/student.service';
import { lessonReportService } from '@/services/lessonReport.service';
import { weeklyReportService } from '@/services/weeklyReport.service';
import { assignmentService } from '@/services/assignment.service';
import { goalService } from '@/services/goal.service';
import { monthlyReportService } from '@/services/monthlyReport.service';
import { notificationService } from '@/services/notifications.service';
import { scheduleService } from '@/services/schedule.service';
import { leaderService } from '@/services/leader.service';
import { birthdaysService } from '@/services/birthday.service';
import { fileService } from '@/services/file.service';
import { groupClassesService } from '@/services/groupClasses.service';
import { releaseService } from '@/services/release.service';

export const $api = {
	auth: authService,
	org: orgService,
	staff: staffService,
	room: roomService,
	boarding: dayCareClassService,
	student: studentService,
	lessonReport: lessonReportService,
	weeklyReport: weeklyReportService,
	assignment: assignmentService,
	goal: goalService,
	monthlyReport: monthlyReportService,
	notification: notificationService,
	schedule: scheduleService,
	leader: leaderService,
	birthday: birthdaysService,
	file: fileService,
	groupClasses: groupClassesService,
	release: releaseService
};
