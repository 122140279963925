import { AxiosResponse } from 'axios';
import { $http } from '@/plugins/axios';

export const leaderService = {
	fetchContact: (params: any, isAdmin = true): Promise<AxiosResponse> => {
		return $http.get(
			`/v1/${isAdmin ? 'org_admin' : 'accountant'}/contracts`,
			{
				params,
			}
		);
	},
	fetchDashboard: (params: any): Promise<AxiosResponse> => {
		return $http.get('v1/org_admin/dashboard_stats', {
			params,
		});
	},
	fetchDashboardOrg: (params: any): Promise<AxiosResponse> => {
		return $http.get('v1/org_admin/org_dashboard/stats', {
			params,
		});
	},
};
