import { $http } from '@/plugins/axios';
import { AxiosResponse } from 'axios';

export const scheduleService = {
  staffHasSchedule: (params: any): Promise<AxiosResponse> => {
    return $http.get(
      'v1/org_admin/staff_time_slots/has_schedule_with_student',
      {
        params,
      }
    );
  },

  fetchTimetableByWeek: (
    params: any,
    oldData?: any
  ): Promise<AxiosResponse> => {
    return $http.get(
      'v1/staff/time_slots/by_week',
      oldData
        ? {
          params,
          headers: {
            common: oldData,
          },
          // eslint-disable-next-line no-mixed-spaces-and-tabs
        }
        : {
          params,
          // eslint-disable-next-line no-mixed-spaces-and-tabs
        }
    );
  },

  fetchTimetableByWeekForGroupClass: (
    params: any
  ): Promise<AxiosResponse> => {
    return $http.get('v1/staff/group_time_slots/by_week', {
      params,
    });
  },
  fetchTimetableByWeekGroupClass: (
    params: any
  ): Promise<AxiosResponse> => {
    return $http.get('v1/staff/group_time_slots/by_week_for_all_students', {
      params,
    });
  },

  fetchTimetableByWeekDaycare: (
    params: any,
    oldData?: any
  ): Promise<AxiosResponse> => {
    return $http.get(
      'v1/org_admin/day_care_class_schedules/by_week',
      oldData
        ? {
          params,
          headers: {
            common: oldData,
          },
          // eslint-disable-next-line no-mixed-spaces-and-tabs
        }
        : {
          params,
          // eslint-disable-next-line no-mixed-spaces-and-tabs
        }
    );
  },
  fetchTimetableOnDay: (params: any): Promise<AxiosResponse> => {
    return $http.get('/v1/staff/schedule_sessions/by_date', {
      params,
    });
  },
  fetchSessionOnToDay: (params: any): Promise<AxiosResponse> => {
    return $http.get('/v1/staff/schedule_sessions', {
      params,
    });
  },

  fetchTimetableScheduleOnDay: (params: any): Promise<AxiosResponse> => {
    return $http.get('/v1/org_admin/day_care_class_schedules/by_date', {
      params,
    });
  },

  fetchTimetableScheduleOnDayCare: (params: any): Promise<AxiosResponse> => {
    return $http.get(
      '/v1/org_admin/day_care_class_schedules/student_class_sessions',
      {
        params,
      }
    );
  },

  fetchTimetableScheduleOnDayCareByStaff: (
    params: any
  ): Promise<AxiosResponse> => {
    return $http.get('/v1/org_admin/staff_class_sessions', {
      params,
    });
  },

  fetchTimetableScheduleOnDayCareByClass: (
    params: any
  ): Promise<AxiosResponse> => {
    return $http.get('/v1/org_admin/class_sessions', {
      params,
    });
  },

  fetchTimetableHistory: (params: any): Promise<AxiosResponse> => {
    return $http.get('v1/org_admin/timetables', {
      params,
    });
  },
  fetchTimetableHistoryStats: (
    id: string,
    params: any
  ): Promise<AxiosResponse> => {
    return $http.get(`v1/org_admin/timetables/${ id }/stats`, {
      params,
    });
  },
  fetchTimetableScheduleActive: (): Promise<AxiosResponse> => {
    return $http.get('/v1/org_admin/timetables/active');
  },
  checkDownloadNewFile: (): Promise<AxiosResponse> => {
    return $http.get('v1/org_admin/timetables/check_download');
  },
  checkDownloadNewFileById: (id: number | string): Promise<AxiosResponse> => {
    return $http.get(
      `v1/org_admin/timetables/export_token_by_timetable_id?timetable_id=${ id }`
    );
  },

  checkDownloadCurrentFile: (params?: any): Promise<AxiosResponse> => {
    return $http.get('v1/org_admin/timetables/export_token', {
      params,
    });
  },
  uploadTimeTableFile(data: any) {
    return $http.post(`/v1/org_admin/timetables/upload_excel`, data, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=plug_conn_test',
      },
    });
  },
  createTimeTable(data: any) {
    return $http.post(`v1/org_admin/timetables`, data);
  },
  createTimeTableStudent(id: number, data: any) {
    return $http.post(
      `v1/org_admin/students/${ id }/student_timetable`,
      data
    );
  },
  createTimeSlot(data: any, isMany = false) {
    return $http.post(
      `v1/org_admin/time_slots${ isMany ? '/create_many' : '' }`,
      data
    );
  },
  updateTimeSlot(id: number, data: any) {
    return $http.put(`v1/org_admin/time_slots/${ id }`, data);
  },
  getHolidays: (params: any): Promise<AxiosResponse> => {
    return $http.get('v1/org_admin/holidays', {
      params,
    });
  },
  createHoliday: (data: any): Promise<AxiosResponse> => {
    return $http.post('v1/org_admin/holidays', data);
  },
  updateHoliday: (id: string, data: any): Promise<AxiosResponse> => {
    return $http.put(`v1/org_admin/holidays/${ id }`, data);
  },
  deleteHoliday: (id: string): Promise<AxiosResponse> => {
    return $http.delete(`v1/org_admin/holidays/${ id }`);
  },

  checkUnSchedule(payload: any) {
    return $http.post(
      `v1/org_admin/schedule_sessions/availability`,
      payload
    );
  },
  createUnSchedule(student_id: string, payload: any) {
    return $http.post(
      `v1/org_admin/students/${ student_id }/schedule_sessions/unscheduled`,
      payload
    );
  },
  createUnScheduleDaycare(payload: any) {
    return $http.post(
      `/v1/org_admin/day_care_class_schedules/student_class_sessions`,
      payload
    );
  },
  cancelUnScheduleDaycareFuture(payload: any) {
    return $http.put(
      `v1/org_admin/day_care_class_schedules/student_class_sessions`,
      payload
    );
  },
  cancelUnScheduleDaycare(payload: any) {
    return $http.put(`v1/org_admin/class_sessions/batch_cancel`, payload);
  },
  cancelUnScheduleDaycareByStaff(payload: any) {
    return $http.put(
      `v1/org_admin/staff_class_sessions/batch_cancel`,
      payload
    );
  },
  cancelSchedule(payload: any) {
    return $http.put(
      `v1/org_admin/schedule_sessions/batch_cancel`,
      payload
    );
  },
  cancelScheduleOne(params: any, payload: any, student_id: string) {
    return $http.put(
      `v1/org_admin/students/${ student_id }/schedule_sessions/cancel`,
      payload,
      {
        params,
      }
    );
  },
  unCancelSchedule(student_id: string, id: string, payload: any) {
    return $http.put(
      `v1/org_admin/students/${ student_id }/schedule_sessions/uncancel?id=${ id }`,
      payload
    );
  },
  unCancelScheduleDayCare(id: string) {
    return $http.put(
      `v1/org_admin/day_care_class_schedules/student_class_sessions/restore/${ id }`
    );
  },
  unCancelScheduleDayCareStaff(payload: any) {
    return $http.put(
      `v1/org_admin/staff_class_sessions/batch_uncancel`,
      payload
    );
  },
  unCancelScheduleDayCareClass(payload: any) {
    return $http.put(`v1/org_admin/class_sessions/batch_uncancel`, payload);
  },
  updateCancelSession(student_id: string, node_id: string, payload: any) {
    return $http.put(
      `v1/org_admin/students/${ student_id }/schedule_sessions/update_notes?id=${ node_id }`,
      payload
    );
  },
  changeStaffForOnly(student_id: string, id: string, payload: any) {
		return $http.put(
			`v1/org_admin/students/${student_id}/schedule_sessions/update_staff?id=${id}`,
			payload
		);
	},
  changeStaff(payload: any) {
    return $http.put(
      `v1/org_admin/schedule_sessions/batch_update_staff`,
      payload
    );
  },
  staffListChangeTask(payload: any) {
    return $http.post(
      `v1/org_admin/schedule_sessions/staffs_to_be_updated`,
      payload
    );
  },
  changeTimeSlot(params: any, student_id: string, payload: any) {
    return $http.put(
      `v1/org_admin/students/${ student_id }/schedule_sessions/update_time`,
      payload,
      {
        params,
      }
    );
  },
  deleteTimeSlot(id: string) {
    return $http.delete(`v1/org_admin/schedule_sessions/${ id }`);
  },

  scheduleStatsByMonth: (params: any): Promise<AxiosResponse> => {
    return $http.get('v1/org_admin/schedule_sessions/stats_by_month', {
      params,
    });
  },

  scheduleStatsByMonthDayCare: (params: any): Promise<AxiosResponse> => {
    return $http.get('/v1/org_admin/day_care_class_schedules/by_month', {
      params,
    });
  },

  scheduleStatsStudentByMonth: (params: any): Promise<AxiosResponse> => {
    return $http.get('v1/org_admin/schedule_sessions/by_month', {
      params,
    });
  },

  scheduleStatsStudentByMonthGroupClass: (
    params: any
  ): Promise<AxiosResponse> => {
    return $http.get('v1/org_admin/group_sessions/by_month', {
      params,
    });
  },

  studentTimetable: (id: number, params: any): Promise<AxiosResponse> => {
    return $http.get(`/v1/org_admin/students/${ id }/student_timetable`, {
      params,
    });
  },

  deleteTimeSlotStudent: (id: number): Promise<AxiosResponse> => {
    return $http.post(`/v1/org_admin/time_slots/${ id }/deactivate`);
  },

  deleteManyTimeSlotStudent: (data: any): Promise<AxiosResponse> => {
    return $http.put(`/v1/org_admin/time_slots/deactivate_many`, data);
  },
};
