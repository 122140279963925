import { createStore } from 'vuex';
// @ts-ignore
import VuexPersistence from 'vuex-persist';

const vuexPersist = new VuexPersistence({
	storage: localStorage,
});

export default createStore({
	state: {
		alert: {
			type: 'success',
			message: '',
		},
		alertDontHide: {
			type: 'success',
			message: '',
		},
		profile: {
			current_org: {
				name: '',
				org_id: '',
				subdomain: '',
			},
			orgs: [],
			staff: {
				id: 0,
				email: '',
				full_name: '',
				name: '',
				phone_number: '',
				role: '',
				state: '',
			},
		},
		sideBarisHide: false,
		goalSelected: [],
		folderSelected: [],
		allFolderChildSelected: [],
		goalHides: [] as any,
		goalAdded: [] as any,
		reload: 0,
	},
	getters: {
		isTeacherAndAccountant(state) {
			return ['teacher', 'accountant'].includes(state.profile.staff.role);
		},
		isTeacher(state) {
			return ['accountant'].includes(state.profile.staff.role);
		},
		isAccountant(state) {
			return ['accountant'].includes(state.profile.staff.role);
		},
		isAdmin(state) {
			return ['leader', 'support', 'admin', 'org_admin'].includes(
				state.profile.staff.role
			);
		},
		isManager(state) {
			return ['leader', 'support', 'org_admin'].includes(
				state.profile.staff.role
			);
		},
		isAdminAndAccountant(state) {
			return [
				'leader',
				'support',
				'admin',
				'org_admin',
				'accountant',
			].includes(state.profile.staff.role);
		},
		goalsSelected(state) {
			return state.goalSelected;
		},
		foldersSelected(state) {
			return state.folderSelected;
		},
		childFoldersSelected(state) {
			return state.allFolderChildSelected;
		},
		goalHides(state) {
			return state.goalHides;
		},
		goalAdded(state) {
			return state.goalAdded;
		},
		reload(state) {
			return state.reload;
		},
	},
	mutations: {
		SET_ALERT(state, data) {
			state.alert = {
				type: data.type || 'success',
				message: data.message,
			};
			setTimeout(() => {
				state.alert = {
					type: '',
					message: '',
				};
			}, 3000);
		},
		SET_ALERT_DONT_HIDE(state, data) {
			state.alertDontHide = {
				type: data.type || 'success',
				message: data.message,
			};
		},
		CLEAR_ALERT(state) {
			state.alert = {
				type: '',
				message: '',
			};
			state.alertDontHide = {
				type: '',
				message: '',
			};
		},
		SET_PROFILE(state, data) {
			state.profile = data;
		},
		SET_SIDEBAR(state, data) {
			state.sideBarisHide = data;
		},
		SELECTED(state, data) {
			state.goalSelected = data;
		},
		FOLDER_SELECTED(state, data) {
			state.folderSelected = data;
		},
		FOLDER_CHILD_SELECTED(state, data) {
			state.allFolderChildSelected = data;
		},
		GOAL_HIDE(state, data) {
			const { goal } = data;
			const index = state.goalHides.findIndex((g: any) => g === goal.id);
			if (index !== -1) {
				state.goalHides.splice(index, 1);
			} else {
				state.goalHides.push(goal.id);
			}
		},
		GOAL_ADD(state, id: string) {
			const index = state.goalAdded.findIndex((g: any) => g === id);
			if (index !== -1) {
				state.goalAdded.splice(index, 1);
			} else {
				state.goalAdded.push(id);
			}
		},
		GOAL_REMOVE(state, id: string) {
			const index = state.goalAdded.findIndex((g: any) => g === id);
			if (index !== -1) {
				state.goalAdded.splice(index, 1);
			} else {
				state.reload = state.reload + 1;
			}
		},
		CLEAR_ADDED(state) {
			state.goalAdded = [];
			state.reload = 0;
		},
		RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION,
	},
	actions: {
		setAlertMessage({ commit }, data) {
			commit('SET_ALERT', data);
		},
		setAlertMessageDontHide({ commit }, data) {
			commit('SET_ALERT_DONT_HIDE', data);
		},
		clearAlert({ commit }) {
			commit('CLEAR_ALERT');
		},
		setProfile({ commit }, data) {
			commit('SET_PROFILE', data);
		},
		setSideBar({ commit }, data) {
			commit('SET_SIDEBAR', data);
		},
		selected({ commit, state }, data) {
			const { goals, isAll } = data;
			const addItems = goals.filter(
				(item: any) =>
					!state.goalSelected.find((g: any) => g.id === item.id)
			);
			if (!isAll) {
				const noneExistItems = state.goalSelected.filter(
					(item: any) => !goals.find((g: any) => g.id === item.id)
				);
				commit('SELECTED', noneExistItems);
			}
			commit('SELECTED', [...state.goalSelected, ...addItems]);

			if (state.goalSelected?.length) {
				commit('FOLDER_SELECTED', []);
			}
		},
		childFolderSelected({ commit, state }, data) {
			const { folders } = data;
			const addItems = folders.filter(
				(item: any) =>
					!state.allFolderChildSelected.find(
						(g: any) => g.id === item.id
					)
			);
			const noneExistItems = state.allFolderChildSelected.filter(
				(item: any) => !folders.find((g: any) => g.id === item.id)
			);
			commit('FOLDER_CHILD_SELECTED', noneExistItems);
			commit('FOLDER_CHILD_SELECTED', [
				...state.allFolderChildSelected,
				...addItems,
			]);
		},
		folderSelected({ commit, state }, data) {
			const { folder } = data;
			const indexOf = state.folderSelected.findIndex(
				(item: any) => item.id === folder.id
			);
			if (indexOf === -1) {
				commit('FOLDER_SELECTED', [...state.folderSelected, folder]);
			} else {
				const newState = [...state.folderSelected];
				newState.splice(indexOf, 1);
				commit('FOLDER_SELECTED', newState);
			}

			if (state.folderSelected?.length) {
				commit('SELECTED', []);
			} else {
				commit('FOLDER_CHILD_SELECTED', []);
			}
		},
		hide({ commit }, data) {
			commit('GOAL_HIDE', data);
		},
		clearSelected({ commit }) {
			commit('SELECTED', []);
			commit('FOLDER_SELECTED', []);
			commit('FOLDER_CHILD_SELECTED', []);
		},
		clearChildrenSelected({ commit }) {
			commit('FOLDER_CHILD_SELECTED', []);
		},
		addGoal({ commit }, data) {
			commit('GOAL_ADD', data);
		},
		removeGoal({ commit }, data) {
			commit('GOAL_REMOVE', data);
		},
		clearAdded({ commit }) {
			commit('CLEAR_ADDED');
		},
	},
	plugins: [vuexPersist.plugin],
});
