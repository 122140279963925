/* eslint-disable */
import { $http } from '@/plugins/axios';
import { AxiosResponse } from 'axios';

export const assignmentService = {
	getAssignments: (
		id: string,
		isAdmin = true,
		oldData?: any
	): Promise<AxiosResponse> => {
		return $http.get(
			`/v1/${isAdmin ? 'org_admin' : 'staff'}/students/${id}/assignments`,
			oldData
				? {
						headers: {
							common: oldData,
						},
				  }
				: {}
		);
	},

	deletedStaffAssignments: (
		student_id: string,
		staff_id: string
	): Promise<AxiosResponse> => {
		return $http.delete(
			`/v1/org_admin/students/${student_id}/assignments/${staff_id}`
		);
	},
	createStaffAssignments: (
		student_id: string,
		staff_id: string,
		isMain: boolean = false
	): Promise<AxiosResponse> => {
		return $http.post(`/v1/org_admin/students/${student_id}/assignments`, {
			assignment: {
				is_main: isMain,
				staff_id: staff_id,
			},
		});
	},
	changeStaffAssignments: (
		student_id: string,
		assignment_id: string,
		data: any
	): Promise<AxiosResponse> => {
		return $http.delete(
			`/v1/org_admin/students/${student_id}/assignments/${assignment_id}`,
			{ data }
		);
	},
	makeMainStaffAssignments: (
		student_id: string,
		assigment_id: string
	): Promise<AxiosResponse> => {
		return $http.put(
			`/v1/org_admin/students/${student_id}/assignments/${assigment_id}/mark_as_main`
		);
	},
};
