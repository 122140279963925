/* eslint-disable */
import { $http } from '@/plugins/axios';
import { AxiosResponse } from 'axios';

export const birthdaysService = {
	birthdays: (): Promise<AxiosResponse> => {
		return $http.get(`/v1/org_admin/student_birthdays`);
	},

	birthdaysByMonth: (params: any): Promise<AxiosResponse> => {
		return $http.get(`/v1/org_admin/student_birthdays/detail`, {
			params,
		});
	},
};
