<template>
	<Alert v-model:is-open="isOpen" :type="alert.type">{{
		alert.message
	}}</Alert>
	<AlertDontHide v-model:is-open="isOpenDontHide" :type="alertDontHide.type">
		<span v-html="alertDontHide.message"></span>
	</AlertDontHide>
	<component :is="layout" v-model:layout="layout">
		<router-view />
	</component>
	<DaModalFrame v-model:open="modalIsOpen">
		<new-release
			:release="release"
			@close="modalIsOpen = false"
		></new-release>
	</DaModalFrame>
</template>
<script>
import { _LAYOUT_NAME } from '@/enums';
import Alert from '@/components/elements/alert/Alert.vue';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import AlertDontHide from '@/components/elements/alert/AlertDontHide.vue';
import { $api } from '@/services';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import NewRelease from '@/views/newRelease/newRelease.vue';

export default {
	components: {
		AlertDontHide,
		Alert,
		DaModalFrame,
		NewRelease,
	},

	setup() {
		const route = useRoute();
		const store = useStore();
		const isOpen = computed(() => store.state.alert.message !== '');
		const isOpenDontHide = computed(
			() => store.state.alertDontHide.message !== ''
		);
		const modalIsOpen = ref(false);
		const release = ref({});
		const layout = computed(() => {
			return route.meta?.layout || _LAYOUT_NAME.NONE_LAYOUT;
		});

		const getCookie = (cookieName) => {
			const cookies = document.cookie.split(';');
			for (let i = 0; i < cookies.length; i++) {
				const cookie = cookies[i].trim();
				if (cookie.indexOf(cookieName + '=') === 0) {
					return cookie.substring(
						cookieName.length + 1,
						cookie.length
					);
				}
			}
			return null;
		};

		const getNewRelease = async () => {
			let hasShown = getCookie('hasShown');

			if (hasShown) return;
			const res = await $api.release.get();
			if (res.data.data) {
				release.value = res.data.data;
				modalIsOpen.value = true;
			}
			document.cookie = 'hasShown=' + true;
		};

		onMounted(() => {
			getNewRelease();
		});

		return {
			modalIsOpen,
			getCookie,
			release,
			getNewRelease,
			layout,
			isOpen,
			isOpenDontHide,
			alert: computed(() => store.state.alert),
			alertDontHide: computed(() => store.state.alertDontHide),
		};
	},
};
</script>
