/* eslint-disable */
import { $http } from '@/plugins/axios';
import { AxiosResponse } from 'axios';

export const weeklyReportService = {
	weeklyReports: (
		id: string,
		query: string = '',
		oldPayload?: any
	): Promise<AxiosResponse> => {
		return $http.get(
			`/v1/staff/students/${id}/weekly_reports${query}`,
			oldPayload
				? {
						headers: {
							common: oldPayload,
						},
				  }
				: {}
		);
	},
	weeklyReportsList: (query?: string): Promise<AxiosResponse> => {
		return $http.get(`/v1/staff/weekly_reports`, {
			params: query,
		});
	},
	weeklyReportsListGroup: (query?: string): Promise<AxiosResponse> => {
		return $http.get(`/v1/staff/group_weekly_reports`, {
			params: query,
		});
	},
	createWeeklyReportGroup: (
		groupClassId: string,
		data?: any
	): Promise<AxiosResponse> => {
		return $http.post(
			`/v1/staff/group_classes/${groupClassId}/group_weekly_reports`,
			data
		);
	},
	weeklyReportDetailGroup: (
		groupClassId: string,
		id: string
	): Promise<AxiosResponse> => {
		return $http.get(
			`/v1/staff/group_classes/${groupClassId}/group_weekly_reports/${id}`
		);
	},
	weeklyReportDetailGroupFilterByStudent: (
		groupClassId: string,
		id: string,
		studentId: string
	): Promise<AxiosResponse> => {
		return $http.get(
			`/v1/staff/group_classes/${groupClassId}/group_weekly_reports/${id}/get_sessions_count_and_emotion_scale_stats_by_student?student_id=${studentId}`
		);
	},
	weeklyReportsListOldData: (
		studentId: string,
		oldData: any,
		params?: any
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/staff/students/${studentId}/list_weekly_reports`,
			oldData
				? {
						params,
						headers: {
							common: oldData,
						},
				  }
				: { params }
		);
	},
	weeklyReportPublic: (query: string): Promise<AxiosResponse> => {
		return $http.get(`/v1/weekly_reports/show_by_token${query}`);
	},
	getToken(id: string, student_id: string) {
		return $http.post(
			`/v1/staff/students/${student_id}/weekly_reports/${id}/share`
		);
	},
	updateWeeklyReport: (
		id: string,
		weekly_report: { note: string; week_start: string }
	): Promise<AxiosResponse> => {
		return $http.post(`/v1/staff/students/${id}/weekly_reports`, {
			weekly_report,
		});
	},
	skillInReport: (
		groupClassID: string,
		reportId: string
	): Promise<AxiosResponse> => {
		return $http.get(
			`/v1/staff/group_classes/${groupClassID}/group_weekly_reports/${reportId}/active_group_plan`
		);
	},

	assessmentsInReport: (
		groupClassId: string,
		reportId: string,
		params: any
	): Promise<AxiosResponse> => {
		return $http.get(
			`/v1/staff/group_classes/${groupClassId}/group_weekly_reports/${reportId}/student_assessment_group_weekly_reports`,
			{
				params,
			}
		);
	},

	updateAssessmentsInReport: (
		groupClassId: string,
		reportId: string,
		assessmentId: string,
		data: any
	): Promise<AxiosResponse> => {
		return $http.put(
			`/v1/staff/group_classes/${groupClassId}/group_weekly_reports/${reportId}/student_assessment_group_weekly_reports/${assessmentId}`,
			data
		);
	},

	createAssessmentsInReport: (
		groupClassId: string,
		reportId: string,
		data: any
	): Promise<AxiosResponse> => {
		return $http.post(
			`/v1/staff/group_classes/${groupClassId}/group_weekly_reports/${reportId}/student_assessment_group_weekly_reports`,
			data
		);
	},

	updateForGroup: (
		groupClassId: string,
		reportId: string,
		data: any
	): Promise<AxiosResponse> => {
		return $http.put(
			`/v1/staff/group_classes/${groupClassId}/group_weekly_reports/${reportId}`,
			data
		);
	},

	submitNoteReportForStudent: (
		groupClassId: string,
		reportId: string,
		data: any
	): Promise<AxiosResponse> => {
		return $http.post(
			`/v1/staff/group_classes/${groupClassId}/group_weekly_reports/${reportId}/students_group_weekly_reports`,
			data
		);
	},

	submitReport: (
		groupClassId: string,
		reportId: string,
		data: any
	): Promise<AxiosResponse> => {
		return $http.post(
			`/v1/staff/group_classes/${groupClassId}/group_weekly_reports/${reportId}/submit`,
			data
		);
	},

	sendReportGroupClass: (
		student_id: string,
		report_id: string
	): Promise<AxiosResponse> => {
		return $http.post(
			`v1/staff/group_classes/${student_id}/group_weekly_reports/${report_id}/send`
		);
	},
};
