/* eslint-disable */
import { $http } from '@/plugins/axios';
import { AxiosResponse } from 'axios';

export const authService = {
	login: (data: any): Promise<AxiosResponse> => {
		return $http.post('v1/sessions', data);
	},

	register: (data: any): Promise<AxiosResponse> => {
		return $http.post('v1/sign_up_via_invitation', data);
	},

	checkToken: (data: any): Promise<AxiosResponse> => {
		return $http.post('v1/account_tokens/validate_invitation', data);
	},

	forgotPassword: (data: any): Promise<AxiosResponse> => {
		return $http.post('/v1/passwords/forgot', data);
	},

	updatePassword: (data: any): Promise<AxiosResponse> => {
		return $http.post('/v1/passwords/reset', data);
	},

	profile() {
		return $http.get('v1/staff/my_staff');
	},
};
