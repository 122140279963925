import { createApp } from 'vue';
import App from '@/App.vue';
import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css';
import '@/assets/styles/vue3-datetime-picker.css';
import '@/assets/styles/main.scss';
import '@/assets/styles/elements.scss';
import '@/assets/styles/tailwind.css';
import '@/assets/styles/_tooltip.scss';
import '@/registerServiceWorker';
import router from '@/router';
import store from '@/store';
import { createHead } from '@vueuse/head';
import { globalComponents } from '@/utils/import';
import { $http } from '@/plugins/axios';
import { $api } from '@/services';
// @ts-ignore
import VueDatepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import {
	_GET_DATE,
	_GET_DATE_2,
	_GET_DATE_DDMM,
	_GET_DATE_MM_YYYY,
	_GET_DATE_MMYYYY,
	_GET_DATE_TIME,
	_GET_DATE_WITHOUT_TIMEZONE,
	_GET_DATE_YYYY_MM_DD,
	_GET_DAY,
	_GET_MONTH,
	_GET_TIME,
	aFromB,
} from '@/helper/formatDate';
import { _MONTH_DIFF } from '@/helper/monthDiff';
import dayjs from 'dayjs';
import { _GET_ROLE } from '@/helper/formatEnums';
import VueApexCharts from 'vue3-apexcharts';
import { COOPERATION, EMOJI, TAG_RESULT } from '@/helper/tag';
import { TO_YEAR } from '@/helper/toYear';
import { _GET_ONE_BY_KEY } from '@/helper/getByKey';
import { _END_WEEK, _TO_WEEK } from '@/helper/toWeek';
import { CircleStencil, Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import 'vue-advanced-cropper/dist/theme.bubble.css';
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';
import directives from '@/directives';

//----------------------- 🤍 Variable 🤍 -----------------------//
const head = createHead();
const app = createApp(App);

//----------------------- 🤍 Config 🤍 -----------------------//
globalComponents(app);
app.config.globalProperties.$http = $http;
app.config.globalProperties.$api = $api;
app.config.globalProperties.$dayjs = dayjs;

//----------------------- 🤍 Filter 🤍 -----------------------//
app.config.globalProperties.$filters = {
	DAYJS: (data: any) => {
		return dayjs(data);
	},
	formatDate: (value: any) => {
		return _GET_DATE(value);
	},
	formatDateWithoutTimeZone: (value: any) => {
		return _GET_DATE_WITHOUT_TIMEZONE(value);
	},
	formatTime: (value: any) => {
		return _GET_TIME(value);
	},
	formatDateTime: (value: any) => {
		return _GET_DATE_TIME(value);
	},
	formatDateDDMM: (value: any) => {
		return _GET_DATE_DDMM(value);
	},
	formatDateMMYYYY: (value: any) => {
		return _GET_DATE_MMYYYY(value);
	},
	formatDate2: (value: any) => {
		return _GET_DATE_2(value);
	},
	getDay: (value: any) => {
		return _GET_DAY(value);
	},
	monthDiff: (value_1: any, value_2: any) => {
		return _MONTH_DIFF(value_1, value_2);
	},
	role(role: string) {
		return _GET_ROLE(role);
	},
	tagResult(tag: string) {
		return TAG_RESULT(tag);
	},
	emoji(tag: string) {
		return EMOJI(tag);
	},
	toYear(data: number) {
		return TO_YEAR(data);
	},
	toWeek(data: string, format?: boolean) {
		return _TO_WEEK(data, format);
	},
	toMonth(data: string) {
		return _GET_MONTH(data);
	},
	endWeek(data: string) {
		return _END_WEEK(data);
	},
	toYYYYMMDD(data: string) {
		return _GET_DATE_YYYY_MM_DD(data);
	},
	toMMYYY(date: string) {
		return _GET_DATE_MM_YYYY(date);
	},
	cooperation(data: string) {
		return COOPERATION(data);
	},
	getOneByKey(array: any[], key: string, value: string) {
		return _GET_ONE_BY_KEY(array, key, value);
	},
	sourceMap(data: string, editable = false) {
		switch (data) {
			case 'new':
				if (editable) {
					return 'MT mới chưa dạy';
				}
				return 'MT mới đã dạy';
			case 'stopped':
				return 'MT tạm dừng';
			case 'library':
				return 'Ngân hàng MT';
			default:
				return 'MT lặp lại';
		}
	},
	aFromB(a: any, b: any) {
		if (!a || !b) {
			return '';
		}
		return aFromB(a, b);
	},
};

//----------------------- 🤍 Component Global 🤍 -----------------------//
app.component('VueDatepicker', VueDatepicker);
app.component('Cropper', Cropper);
app.component('CircleStencil', CircleStencil);

//----------------------- 🤍 Use 🤍 -----------------------//

app.use(VueApexCharts, {} as any);
app.use(store);
app.use(router);
app.use(head);
app.use(VCalendar, {});
directives(app);
app.mount('#app');
