import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52a50840"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex-shrink-0" }
const _hoisted_3 = { class: "ml-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExclamationCircleIcon = _resolveComponent("ExclamationCircleIcon")!

  return (_openBlock(), _createBlock(_Transition, {
    "enter-active-class": "transform ease-out duration-300 transition",
    "enter-from-class": "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2",
    "enter-to-class": "translate-y-0 opacity-100 sm:translate-x-0",
    "leave-active-class": "transition ease-in duration-100",
    "leave-from-class": "opacity-100",
    "leave-to-class": "opacity-0"
  }, {
    default: _withCtx(() => [
      ($props.isOpen)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            style: {"z-index":"1000"},
            class: _normalizeClass(["rounded-md p-4 fixed _alert md-down:bottom-4 md:top-4", 
				$props.type === 'danger' || $props.type === 'error'
					? 'bg-sienna-100'
					: 'bg-green-50'
			])
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_ExclamationCircleIcon, {
                  class: _normalizeClass(["h-5 w-5", 
							$props.type === 'danger' || $props.type === 'error'
								? 'text-sienna-600'
								: 'text-green-400'
						]),
                  "aria-hidden": "true"
                }, null, 8, ["class"])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("p", {
                  class: _normalizeClass(["text-sm font-medium", 
							$props.type === 'danger' || $props.type === 'error'
								? 'text-sienna-600'
								: 'text-green-800'
						])
                }, [
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                ], 2)
              ])
            ])
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}