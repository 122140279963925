export function TAG_RESULT(data: string) {
  if (!data) {
    return data;
  }
  let color = '';
  let background = '';

  let result = data;

  if (!Number(data)) {
    result = data.replace(/[^\d.]/g, '').replace(/\.([.\d]+)$/, function (_, m1) {
      return '.' + m1.replace(/\./g, '')
    })

    if (result?.length >= 3 && +result !== 100) {
      result = result.substr(0, 2) + '.' + result.substr(2, 1);
    }
  } else {
    result = Number(data).toString();
  }

  if (!result) {
    result = 'x';
  }
  if (+result > 80) {
    background = '#2AA797';
    color = '#FFFFFF';
  } else if ((+result >= 55 && +result <= 80) || +result === 5575) {
    background = '#65A30D';
    color = '#FFFFFF';
  } else if ((+result >= 30 && +result < 55) || +result === 3055) {
    background = '#F59E0B';
    color = '#FFFFFF';
  } else if (+result < 30 && +result >= 0) {
    background = '#F87171';
    color = '#FFFFFF';
  } else {
    background = '#9CA3AF';
    color = '#FFFFFF';
  }
  switch (data.toLowerCase()) {
    case 'đạt > 80%':
      background = '#2AA797';
      color = '#FFFFFF';
      break;
    case 'đạt 55 - 75%':
      background = '#65A30D';
      color = '#FFFFFF';
      break;
    case 'đạt 30 - 50%':
      background = '#F59E0B';
      color = '#FFFFFF';
      break;
    case 'đạt < 25%':
      background = '#F87171';
      color = '#FFFFFF';
      break;
  }
  return {
    color,
    background,
  };
}

export function EMOJI(data: string) {
  let emoji = '';
  let message = '';
  switch (data) {
    case '1':
      message = 'Con cảm thấy bùng nổ';
      emoji = '0b356bb507d64b9489e2fbced131f795.png';
      break;
    case '2':
      message = 'Con cảm thấy tức giận';
      emoji = '5ede936757cd4fbdb6247bad22bf454d.png';
      break;
    case '3':
      message = 'Con khó chịu';
      emoji = 'cf1f643bc1244f5e8e37767bc946d607.png';
      break;
    case '4':
      message = 'Con bình thường';
      emoji = '5565d66a50b44df9ae37a955bdfb3757.png';
      break;
    case '5':
      message = 'Con vui vẻ';
      emoji = 'happy.png';
      break;
  }
  return { emoji, message };
}

export function COOPERATION(data: string) {
  let message = '';
  switch (data) {
    case '1':
      message = 'Thụ động, không hợp tác và không tập trung';
      break;
    case '2':
      message = 'Ít chủ động, kém hợp tác và kém tập trung';
      break;
    case '3':
      message = 'Ít chủ động, có hợp tác và tập trung';
      break;
    case '4':
      message = 'Có chủ động, có hợp tác và tập trung';
      break;
    case '5':
      message = 'Rất chủ động, hợp tác tốt và tập trung cao';
      break;
  }
  return message;
}

export function TAG_COLOR(data: string) {
  let background = '';
  if (+data > 80) {
    background = '#2AA797';
  } else if ((+data >= 55 && +data <= 80) || +data === 5575) {
    background = '#65A30D';
  } else if ((+data >= 30 && +data < 55) || +data === 2555) {
    background = '#F59E0B';
  } else if (+data < 30 && +data > 0) {
    background = '#F87171';
  } else {
    background = '#9CA3AF';
  }
  return background;
}

export function TAG_LABEL(data: number | string, other?: string) {
  let text = '';
  if (+data > 80) {
    text = 'Đạt 80-100%';
  } else if ((+data >= 55 && +data <= 75) || +data === 5575) {
    text = 'Đạt 55-75%';
  } else if ((+data >= 30 && +data < 55) || +data === 2555) {
    text = 'Đạt 30-50%';
  } else if (+data < 30 && +data > 0) {
    text = 'Đạt 5-25%';
  } else {
    text = other === 'other' ? 'Không có cơ hội' : 'Từ chối';
  }
  return text;
}
