/* eslint-disable */
import { $http } from '@/plugins/axios';
import { AxiosResponse } from 'axios';

export const roomService = {
	getRooms: (): Promise<AxiosResponse> => {
		return $http.get('v1/org_admin/rooms');
	},
	createRoom: (data: any): Promise<AxiosResponse> => {
		return $http.post(`v1/org_admin/rooms`, data);
	},
	deleteRoom: (id: string): Promise<AxiosResponse> => {
		return $http.delete(`v1/org_admin/rooms/${id}`);
	},
	updateRoom: (id: string, data: any): Promise<AxiosResponse> => {
		return $http.put(`v1/org_admin/rooms/${id}`, data);
	},
};
