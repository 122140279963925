/* eslint-disable */
import { $http } from '@/plugins/axios';
import { AxiosResponse } from 'axios';

export const dayCareClassService = {
	getDayCareClasses: (
		isAdmin = true,
		oldData?: any
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/${isAdmin ? 'org_admin' : 'staff'}/day_care_classes`,
			oldData
				? {
						headers: {
							common: oldData,
						},
				  }
				: {}
		);
	},

	createDayCareClass: (data: any): Promise<AxiosResponse> => {
		return $http.post(`v1/org_admin/day_care_classes`, data);
	},

	deleteDayCareClass: (id: string): Promise<AxiosResponse> => {
		return $http.delete(`v1/org_admin/day_care_classes/${id}`);
	},

	updateDayCareClass: (id: string, data: any): Promise<AxiosResponse> => {
		return $http.put(`v1/org_admin/day_care_classes/${id}`, data);
	},

	updateCalender: (data: any): Promise<AxiosResponse> => {
		return $http.put(`v1/org_admin/class_time_slots`, data);
	},

	updateCalenderStudent: (data: any): Promise<AxiosResponse> => {
		return $http.put(`v1/org_admin/student_class_time_slots`, data);
	},

	memberships(student_id: string, id: string) {
		return $http.post(
			`v1/org_admin/students/${student_id}/day_care_class_memberships`,
			{
				membership: {
					day_care_class_id: id,
				},
			}
		);
	},
	membershipsStaffList(id: string) {
		return $http.get(
			`v1/org_admin/staff_day_care_memberships?day_care_class_id=${id}`
		);
	},
	deleteMembershipsStaff(id: string) {
		return $http.delete(`v1/org_admin/staff_day_care_memberships/${id}`);
	},
	membershipsStaff(id: string, staff_id: string) {
		return $http.post(`v1/org_admin/staff_day_care_memberships`, {
			staff_membership: {
				day_care_class_id: id,
				staff_id: staff_id,
			},
		});
	},
	deleteMemberships(student_id: string, id: string) {
		return $http.delete(
			`v1/org_admin/students/${student_id}/day_care_class_memberships/${id}`
		);
	},
	getStudentInClass(id: string) {
		return $http.get(
			`/v1/org_admin/day_care_classes/${id}/day_care_class_memberships/all`
		);
	},
	getStudentInClassCanAdd() {
		return $http.get(`v1/org_admin/students_no_have_day_care_class`);
	},
	addStudents(id: string, payload: any) {
		return $http.post(
			`/v1/org_admin/day_care_classes/${id}/day_care_class_memberships/insert_all`,
			payload
		);
	},
	addStaffToDaycareClass: (data?: any): Promise<AxiosResponse> => {
		return $http.post(
			`v1/org_admin/staff_day_care_memberships/create_or_disable_all`,
			data
		);
	},
};
