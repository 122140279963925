
import { ExclamationCircleIcon } from '@heroicons/vue/solid';
import { useStore } from 'vuex';

export default {
	name: 'AlertDontHide',
	props: {
		isOpen: {
			default() {
				return false;
			},
		},
		type: {
			default() {
				return 'success';
			},
		},
	},
	components: {
		ExclamationCircleIcon,
	},
	setup() {
		const store = useStore();

		function setAlert() {
			store.dispatch('setAlertMessage', { message: '', type: 'success' });
		}

		return {
			setAlert,
		};
	},
};
