
import { Options, Vue } from 'vue-class-component';
import { Emit, PropSync, Watch } from 'vue-property-decorator';

@Options({
	name: 'DaModalFrame',
})
export default class DaModalFrame extends Vue {
	@PropSync('open', { type: Boolean }) isOpen!: boolean;

	//----------------------- 🤍 Emits 🤍 -----------------------//
	@Emit('close')
	onClose() {
		this.isOpen = false;
	}

	//----------------------- 🤍 Watch 🤍 -----------------------//
	@Watch('isOpen', { immediate: true })
	onIsOpenChange(value: boolean) {
		if (value) {
			document.body.classList.add('disable-scroll');
		} else {
			document.body.classList.remove('disable-scroll');
		}
	}

	//----------------------- 🤍 Before destroy 🤍 -----------------------//
	beforeUnmount() {
		document.body.classList.remove('disable-scroll');
	}
}
